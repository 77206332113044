
































































import { Component, Vue } from 'vue-property-decorator'
import { password } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { Login } from '../../types/common'
import { apis } from '../../api/users'

@Component({
  name: 'Login'
})
export default class extends Vue {
  private loginForm = {
    loginId: '',
    password: '',
    verifyCode: ''
  }

  private loading = false
  private loginRules = {
    loginId: [{ required: true, message: '请输入员工工号/手机号/邮箱', trigger: 'blur' }],
    password: [{ validator: password, trigger: 'blur' }]
  }

  private verifyCode = apis.getVerifyCode + '?d=' + Number(new Date())

  $refs!: {
    loginModule: ElForm;
  }

  verifyCodeUpdate () {
    this.verifyCode = apis.getVerifyCode + '?d=' + Number(new Date())
  }

  login () {
    this.loading = true
    this.$axios.post<Login>(apis.login, this.loginForm).then(res => {
      window.localStorage.setItem('token', res.token)
      window.localStorage.setItem('userInfo', JSON.stringify(res.user))
      this.$router.push('/')
    }).catch(() => {
      this.verifyCodeUpdate()
    }).finally(() => {
      this.loading = false
    })
  }

  handleLogin () {
    this.$refs.loginModule.validate(valid => {
      if (valid) {
        this.login()
      }
    })
  }
}
